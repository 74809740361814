/* passwordbox.css */

.rounded-textbox {
    position: relative;
    margin-bottom: 20px;
  }
  
  .textbox-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: 0.3s;
  }
  
  .textbox-input-container {
    position: relative;
  }
  
  .textbox-input {
    width: calc(100% - 40px);
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
  }
  
  .toggle-password-button {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    color: #000;
    visibility: hidden;
    transition: visibility 0.3s ease;
  }
  
  .toggle-password-button.visible {
    visibility: visible;
  }
  
  /* Styles for when the text box is focused */
  .rounded-textbox.focused .textbox-label {
    top: 5px;
    font-size: 0.8rem;
    color: #555;
  }
  
  .rounded-textbox.focused .textbox-input {
    border-color: #4285F4;
  }
  
  .rounded-textbox.focused .toggle-password-button {
    visibility: visible;
    z-index: 1;
  }
  