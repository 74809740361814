.padding-needed{
  padding-right: 5px;
  margin-right: 20px;
}

.home-container {
    margin-top: 60px; 
    margin-left: 260px; 
    padding: 20px; 
    padding-right: 0%;
    background-color: #F7F7F9;
    padding-bottom: 50px;
  }

  p {
    font-size: 1.2rem; 
    line-height: 2; 
    color: #333; 
  }

  .custom-button {
    background-color: #F75151;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px;
    display: block;
    margin-top: 20px;
    cursor: pointer;
    font-size: 1rem; 
    text-align: center;
    text-decoration: none;
  }
  
  .custom-button:hover {
    background-color: #E64040; 
  }

  .offerings{
    margin-top: 60px;
    margin-left: 260px;
    padding: 20px;
    background-color: #F7F7F9;
  }

  

  .message-item {
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .message-item p {
    margin: 5px 0;
    font-size: 16px;
  }
  
  .message-item p strong {
    font-weight: bold;
  }
  

  .heading-offerings {
    margin-bottom: 1.5rem;
  }
  .title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }

  .prospect {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }

  .generated-text {
    font-size: 14px;
    color: #555;
  }
  
  .last-updated {
    font-size: 12px;
    color: #777;
  }

  .home-button-offering {
    margin-bottom: 20px;
  }

  /* Add this to your CSS file */
.error-message {
  background-color: #ffcccc; /* Light red background color */
  color: #8b0000; /* Dark red text color */
  border: 2px solid #8b0000; /* Dark red border */
  padding: 15px; /* Appropriate padding */
  margin: 20px 0; /* Add some margin for better spacing */
  width: 100%; /* Occupy entire width */
  box-sizing: border-box; /* Include padding and border in the width calculation */
  text-align: center;
}

/*Home page styling */
.home-container h1 {
  font-family: "Cabin Sketch", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  
}

.neucha-regular {
  font-family: "Neucha", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.custom {
  font-family: var(--bs-font-sans-serif);
}
.dashboard-container {
  padding: 20px;
  background-color: rgba(247,247,249);
  width: 100%;
  padding-right: 40px;
}

.dashboard-heading {
  text-align: center;
  margin-bottom: 20px;
}

.dashboard-row {
  margin-bottom: 20px;
}

.dashboard-card {
  border: 1px solid #ced4da;
  border-radius: 8px;
  transition: box-shadow 0.3s;
}

.dashboard-card:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.highlighted {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dashboard-card-title {
  font-size: 18px;
  font-weight: bold;
}

.dashboard-card-text {
  font-size: 16px;
}

.messages-header{
  font-size: revert-layer;
  padding-top: 1rem;
  font-family: "Cabin Sketch", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding-bottom: 1rem;
}

.orange-color{
  color: #183B65;
}

.headings-messages{
  font-family: "Cabin Sketch", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.padding-needed{
  padding-right: 5px;
}

.hyperlink-messages{
  color: blue;
  font-family: "Neucha", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-decoration: none;
}
.counter {
  text-align: right;
  font-size: 14px;
  color: #777;
}












  
  
  
  
  
  
  