.signup-container {
    display: flex;
    height: 100vh;
    margin-top: 0;/* Set the height of the container to the full viewport height */
    
  }
  
  .left-part {
    width: 40%;
    background-color: #fd7e14;
  }
  
  .right-part {
    width: 60%;
    background-color: #fff;
    
  }
  
  .red-background {
    padding: 10% 0 0 10%;
    color: #fff;
  }
  
  .sales-navi {
    margin-top: 150px;
    font-size: 2rem;
    margin-bottom: 1.5rem;
    font-family: "Cabin Sketch", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .ai-co-pilot {
    font-size: 2.5rem;
    font-family: "Cabin Sketch", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  body, html {
    margin: 0;
    padding: 0;
  }

  .content-right{
    margin-top: 150px;
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .google-button {
    background-color: #4285F4;
    color: #fff;
    padding: 15px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .google-logo {
    width: 20px; /* Adjust the size of the Google logo as needed */
    margin-right: 10px; /* Adjust the spacing between the logo and text */
  }
  
  .signup-title{
    margin-bottom: 1rem;
    text-align: center;
    font-family: "Cabin Sketch", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  h3 {
    width: 100%; 
    text-align: center; 
    /* border-bottom: 1px solid #000;  */
    line-height: 0.1em;
    margin: 10px 0 20px; 
 } 
 
 h3 span { 
     background:#fff; 
     padding:0 10px; 
 }

    .google-button{
        margin-bottom: 2rem;
    }

    .textbox-container {
        display: flex;
        flex-wrap: wrap; /* Allow flex items to wrap to the next line if needed */
      }
      
      .textbox-container .rounded-textbox {
        flex: 1; /* Make each textbox take up equal space */
        margin-right: 10px; /* Adjust spacing between the textboxes */
      }
      
      .signup-button {
        background-color: #F75151;
        color: #fff;
        padding: 15px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1rem;
        display: block;
        width: 100%;
        text-align: center;
      }

      .checkbox-container {
        margin-top: 20px;
        display: flex;
        align-items: center;
      }
      
      .checkbox-container input {
        margin-right: 10px;
        width: 5%;
      }

      .div-for-button {
        text-align: center;
      }

      .additional-css{
        color: black;
        font-size: 1.2rem;
      }
      