/* form_offerings.css */
.offerings-container {
  width: 100%;
  margin: auto;
}

.input-container {
  margin-bottom: 20px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

button {
  background-color: #f75151;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

button:hover {
  background-color: #e64040;
}

.field-container {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.field-container input,
.field-container textarea {
  margin-bottom: 10px;
}

.field-container input[type="text"],
.field-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}

.field-container button {
  background-color: #f75151;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
}

.field-container button:hover {
  background-color: #e64040;
}
.field-container {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f8f9fa;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.field-info {
  margin-bottom: 10px;
}

.offering-name,
.content-title {
  margin: 0;
  color: #333;
}

.context-name,
.content {
  font-size: 16px;
}

.offering-name {
  font-size: 18px;
  font-weight: bold;
}

.content-title {
  font-size: 16px;
  font-weight: bolder;
}

.message-item {
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Button  for default offerings */
.btn {
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
  --bs-btn-font-family: "Neucha", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}

.btn-default {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fd7e14;
  --bs-btn-hover-border-color: #fd7e14;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fd7e14;
  --bs-btn-active-border-color: #fd7e14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
  --bs-gradient: none;
}

.btn-outline-warning {
  --bs-btn-color: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fd7e14;
  --bs-btn-hover-border-color: #fd7e14;
  --bs-btn-focus-shadow-rgb: 253, 126, 20;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fd7e14;
  --bs-btn-active-border-color: #fd7e14;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fd7e14;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fd7e14;
  --bs-gradient: none;
}