@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeInUp {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.fadeInUp.visible {
  animation: fadeInUp var(--animation-duration, 0.1s) ease-in-out forwards;
  opacity: 1;
}

@media (max-width: 639px) {
  .no-animation {
    opacity: 1;
    animation: none;
  }
}

* {
  font-family: "Neucha", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-weight: 400;
  font-style: normal;
}
.hero {
  background-color: #ffffff;
  background-image: url("/public/hero-bg-abstract.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cabin_sketch {
  font-family: Cabin Sketch, system-ui, -apple-system, BlinkMacSystemFont,
    Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue,
    sans-serif;
}

.features {
  background-color: white;
}

.downloadButton {
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
  background-color: #fd7e14;
  border: solid 2px transparent;
  color: white;
  transition: all 0.3s ease-in-out;
}

.downloadButton:hover {
  border: solid 2px #fd7e14;
  background-color: white;
  color: #fd7e14;
  box-shadow: 0px 5px 20px 0px color-mix(in srgb, rgb(9, 9, 9), transparent 85%);
  transform: scale(1.01);
  text-decoration: none;
}

.downloadButton:active {
  background-color: #fd7e14;
  border: solid 2px transparent;
  color: white;
  transform: scale(0.9);
}

.learnMoreButton {
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
  background-color: white;
  border: solid 2px #fd7e14;
  color: #fd7e14;
  transition: all 0.3s ease-in-out;
}

.learnMoreButton:hover {
  border: solid 2px transparent;
  background-color: #fd7e14;
  color: white;
  box-shadow: 0px 5px 20px 0px color-mix(in srgb, rgb(9, 9, 9), transparent 85%);
  transform: scale(1.01);
  text-decoration: none;
}

.learnMoreButton:active {
  border-radius: 255px 25px 225px 25px/25px 225px 25px 255px;
  background-color: white;
  border: solid 2px #fd7e14;
  color: #fd7e14;
  transform: scale(0.8);
}
