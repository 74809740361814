/* ... other styles ... */

.rounded-textbox {
    position: relative;
    margin-bottom: 20px;
  }
  
  .textbox-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: 0.3s;
  }
  
  .textbox-input {
    width: 100%;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
  }
  
  /* Styles for when the text box is focused */
  .rounded-textbox.focused .textbox-label {
    top: 5px;
    font-size: 0.8rem;
    color: #555;
  }
  
  .rounded-textbox.focused .textbox-input {
    border-color: #4285F4;
  }
  
  /* ... other styles ... */
  