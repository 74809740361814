
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    z-index: 800; 
  }
  
  .site-name {
    color: #000000;
    text-decoration: none;
    font-size: 1.5rem; 
  }
  
  .header-menu {
    list-style: none;
    display: flex;
    justify-content: center;
    gap: 20px; 
  }
  
  body {
    margin-top: 70px; 
  }
  