.navbar {
  background-color: #ffffff;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #ffffff;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 49px;
  left: -100%;
  transition: 850ms;
  z-index: 700;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
}

.nav-text a {
  text-decoration: none;
  color: black;
  font-size: 18px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  transition: 300ms;
}

.nav-text a:hover {
  background-color: #fd7e14;
  color: #fff;
}
.nav-text span,
.nav-text svg {
  transition: 300ms;
}

.nav-text a:hover span {
  color: #fff;
}
.nav-text a:hover svg {
  fill: #fff;
}
.nav-menu-items {
  width: 100%;
  padding-top: 50px;
  padding-left: 0px;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

.nav-text.active a {
  background-color: #fd7e14;
}
.nav-text.active span {
  color: #fff;
}
.nav-text.active svg {
  fill: #fff;
}
